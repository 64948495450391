.icon-box-container {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.btn_icon--box {
  cursor: pointer;
  &:hover {
    background-color: theme('colors.primary');
  }
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  &:hover {
    background-color: theme('colors.primary');
  }
}

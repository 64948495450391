.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  height: 100%;
  &-container {
    padding: 1rem 0;
    .icon-container {
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
    }
    .qr-container {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
  }
}
.title-container {
  text-align: center;

  color: theme('colors.black.DEFAULT');
  margin-bottom: 3rem;
  .title {
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
  }
  .subtitle {
    font-size: 1.4rem;
    color: theme('colors.black.dark');
    margin-bottom: 4rem;
  }
}
.status-success-subtitle {
  font-weight: 800;
  color: #000000 !important;
  opacity: 1 !important;
}

.button-container {
  display: flex;
  margin-bottom: 2rem;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 1.2rem;
    width: 100%;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-next {
      margin-left: auto;
    }
  }

  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.layout-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: theme('colors.blue.white') !important;
  box-sizing: border-box;

  .layout-side {
    .layout-header {
      padding: 0;
      width: 100%;
      background-color: transparent;
      z-index: 99;
    }
    .layout-content {
      padding: 0 1.2rem;
      overflow: auto;
    }
  }
}



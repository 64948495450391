.ant-table-wrapper {
  .ant-table {
    .ant-table-body {
      border-radius: 0 0 12px 12px;

      .ant-table-tbody {
        .ant-table-placeholder {
          height: 350px;

          .ant-table-cell {
            border-bottom: none !important;

            .ant-table-expanded-row-fixed {
              width: auto !important;
            }
          }
        }
      }
    }

    .ant-table-header,
    .ant-table-body {
      table {
        padding: 0 30px;
      }
    }

    .ant-table-row:last-child {
      .ant-table-cell {
        border-bottom: 0;
      }
    }
    .ant-table-cell {
      min-height: 5rem !important;
      padding: 20px 20px !important;
      background-color: theme('colors.white.DEFAULT') !important;
      border-bottom: 1px solid theme('colors.gray.light');
      font-family: Avenir;
      font-weight: normal;
      font-size: 1.6rem;

      &::before {
        width: 0 !important;
      }

      // &:last-child {
      //   margin-right: 30px;
      // }
    }

    .ant-table-thead {
      & > tr > th {
        color: theme('colors.black.dark');
        font-weight: 700;
        font-size: 1.5rem;
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
      }
    }
  }

  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      display: none !important;
    }

    .ant-pagination-item {
      border-radius: 1.2rem;
      width: 2.8rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease-out 300ms;
      margin: 0 0.5rem;

      &:hover {
        background-color: theme('colors.primary');
        a {
          color: theme('colors.white.DEFAULT') !important;
        }
      }
    }

    .ant-pagination-item-active {
      border: none;
      color: theme('colors.primary');
      background-color: theme('colors.blue.light');
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_font';
@import './CustomStyles/Antd/styles.scss';

#root {
  height: 100%;
  position: relative;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Avenir', 'My-Avenir';
}

html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-style: normal;
}

div,
p {
  margin: 0;
  padding: 0;
}

a {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

h5 {
  font-weight: 800;
  font-size: 1.4rem;
  line-height: normal;
  font-family: Avenir;
}

h1 {
  font-weight: 900;
  font-size: 2.4rem;
}

.table_title_header {
  color: theme('colors.black.dark');
}

.cursor-pointer {
  cursor: pointer;
}

.menus_box {
  min-width: 12rem;
  height: auto;
  border-radius: 1.2rem;
  padding: 1.5rem;

  .menus_box--item {
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    font-family: My-Avenir;
    border-bottom: 0.1rem solid theme('colors.gray.light');
    text-align: center;
    color: theme('colors.black.DEFAULT');

    &:last-child {
      border-bottom: none;
    }
  }
  .danger {
    color: theme('colors.red.dark');
  }

  .selected {
    color: theme('colors.primary');
  }
}

.display_none {
  display: none;
}

.flex-column-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.flex-column-center-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.color_primary {
  color: theme('colors.gray.light');
}

.fw-800 {
  font-weight: 800;
}

.color__text-hint {
  color: theme('colors.black.dark');
}

.txt__color--primary {
  color: theme('colors.primary');
}

.modal_box {
  :global {
    .ant-modal {
      padding: 0 1.4rem;

      .ant-modal-content {
        border-radius: 2rem;
        padding: 3rem 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-modal-header {
          padding: 0;
          padding-bottom: 2.1rem;
          border: none !important;
        }

        .ant-modal-body {
          padding: 0;
          flex: 1;
          overflow: hidden;
        }

        .ant-modal-footer {
          border: none;
          padding: 0;
          padding-top: 1rem;
        }
      }
    }
  }
}

.modal_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .modal_header--title {
    flex: 1;
    margin-right: 20px;
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1.4rem;
  }
}

.modal_header_has_title {
  border-bottom: 1px solid theme('colors.gray.light');
  padding-bottom: 0.5rem;
}

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  &-container {
    padding: 1rem 0;
    width: 50%;

    .icon-container {
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
    }
    .title-container {
      color: theme('colors.black.DEFAULT');
      text-align: center;
      margin-bottom: 3rem;

      .title {
        font-weight: 800;
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
      }
      .subtitle {
        font-size: 1.4rem;
        opacity: 0.4;
      }
    }

    .form {
      width: 100%;
      &__item {
        height: 20rem;
        display: block !important;

        &-email {
          background: theme('colors.white.DEFAULT') !important;
        }
      }

      .button-container {
        margin-top: 5.4rem;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        button {
          width: 16rem;
          height: 5.6rem;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .button-container {
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 1rem;
      }
      .button-continue {
        margin-left: auto;
      }
    }
    .progress {
      margin-bottom: 1rem;
    }
    .content-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .content-container {
      flex-grow: 1;
    }
  }
}

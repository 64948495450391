.ant-input-number {
  min-height: 4.6rem !important;
  height: 56px;
  padding: 1rem 0;
  border-radius: 1rem;
  background-color: theme('colors.gray.lighter');
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme('colors.black.DEFAULT');
  border-color: transparent;

  &:focus {
    border-color: theme('colors.blue.DEFAULT');
    background-color: theme('colors.white.DEFAULT');
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    display: flex;
    height: 100%;

    .ant-input-number-input {
      height: auto;
      padding: 0 2rem;
    }
  }
}

.ant-input-number-focused {
  border-color: theme('colors.blue.DEFAULT');
  background-color: theme('colors.white.DEFAULT');
}

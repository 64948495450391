.ant-select {
  display: flex;
  align-items: center;
  min-height: 4.6rem;
  border-radius: 1rem;
  background-color: theme('colors.gray.lighter');
  font-size: 1.4rem;
  color: theme('colors.black.DEFAULT') !important;
  border: none;
}

.ant-select-selector {
  width: 100%;
  height: auto;
  border: none !important;
  padding: 0 2rem !important;
  background-color: transparent !important;
}

.ant-select-arrow {
  color: theme('colors.primary');
  font-size: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-container {
    padding: 1rem 0;
    width: 50%;

    .icon-container {
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
    }

    .title-container {
      color: theme('colors.black.DEFAULT');
      text-align: center;
      margin-bottom: 3rem;

      .title {
        font-weight: 800;
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
      }
      .subtitle {
        font-size: 1.4rem;
        opacity: 0.4;
      }
    }
    .form {
      width: 100%;
      &__item {
        height: 10rem;
        display: block !important;
        &-password {
          background: theme('colors.white.DEFAULT') !important;
        }
        input {
          //background-color: transparent !important;
          height: 4.6rem;
        }
      }
    }
  }
}

.button-container {
  margin-top: 5.4rem;
  display: flex;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

.forgot-password-item {
  text-align: center;
  font-weight: 800;
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-login {
      margin-left: auto;
    }
  }
  .progress {
    margin-bottom: 1rem;
  }
  .content-container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
  }
}

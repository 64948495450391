.ant-tooltip {
  font-weight: 800;
  font-size: 1.3rem;

  .ant-tooltip-inner {
    background: theme('colors.black.DEFAULT');
    padding: 6px 12px;
  }
  .ant-tooltip-arrow {
    span {
      background: theme('colors.black.DEFAULT');
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content-container {
    width: 100%;
  }
}

.icon-container {
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
}

.title-container {
  color: theme('colors.black.DEFAULT');
  text-align: center;
  margin-bottom: 3rem;
  .title {
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

.button-container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  button {
    width: 16rem;
    height: 5.6rem;
  }
  .button-continue {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-continue {
      margin-left: auto;
    }
  }
  .progress {
    margin-bottom: 1rem;
  }
  .content-container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
  }
}

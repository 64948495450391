.ant-input:not(.ant-input[type='password']) {
  min-height: 4.6rem !important;
  padding: 1rem 2rem;
  max-height: 12rem !important;
  border-radius: 1rem;
  // background-color: theme('colors.gray.lighter');
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme('colors.black.DEFAULT');
  border-color: transparent;

  &:focus {
    border-color: theme('colors.blue.DEFAULT');
    background-color: theme('colors.white.DEFAULT');
  }
}

.ant-input-number {
  min-height: 4.6rem !important;
  width: 100%;
  border-radius: 1rem;
  background-color: theme('colors.gray.light');
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme('colors.black.DEFAULT');
  border-color: transparent;

  &:focus {
    border-color: theme('colors.blue.DEFAULT');
    background-color: theme('colors.white.DEFAULT');
  }

  .ant-input-number-input {
    height: auto;
  }
}

.ant-input-password {
  min-height: 4.6rem !important;
  padding: 0 12px 0 0;
  border-radius: 1rem;
  background-color: theme('colors.gray.lighter');
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: theme('colors.black.DEFAULT');
  border-color: transparent;

  .ant-input {
    padding: 1rem 2rem !important;
    background-color: transparent;
    height: 100%;
  }
}
.ant-input-affix-wrapper {
  padding-top: 0;
  background-color: theme('colors.gray.lighter');
  padding-bottom: 0;
  .ant-input {
    background-color: transparent;
  }
}

.ant-input-affix-wrapper-focused {
  border-color: theme('colors.blue.DEFAULT');
  background-color: theme('colors.white.DEFAULT');
}

.card {
  margin-bottom: 2rem;
  &__billing {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 1px solid theme('colors.gray.border');
    font-weight: 800;
    font-size: 1.4rem;
    p:nth-child(1) {
      font-size: 1.2rem;
      opacity: 0.4;
    }
  }
  &__billing:last-child {
    border-bottom: none;
    p:nth-child(2) {
      color: theme('colors.primary');
      font-weight: 800;
      font-size: 1.8rem;
    }
  }
  &__copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    max-height: 5.8rem;
    cursor: pointer;
    input {
      width: auto;
      background: transparent !important;
      padding: 0 !important;
      margin-left: 0.6rem;
      font-weight: 800;
      font-size: 1.8rem;
    }
  }
}

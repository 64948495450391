.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  height: 100%;
  &-container {
    padding: 1rem 0;
    .icon-container {
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
    }
    .qr-container {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
  }
}
.title-container {
  text-align: center;

  color: theme('colors.black.DEFAULT');
  margin-bottom: 3rem;
  .title {
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
  }
  .subtitle {
    font-size: 1.4rem;
    color: theme('colors.black.dark');
    margin-bottom: 4rem;
  }
}
.status-success-subtitle {
  font-weight: 800;
  color: #000000 !important;
  opacity: 1 !important;
}

.button-container {
  display: flex;
  margin-bottom: 2rem;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

.waiting-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  .item {
    width: 100px;
    height: 0.8rem;
    border-radius: 1rem;
    background-color: #e6e6e6;
  }

  .first {
    animation: pulse 3300ms infinite ease-in;
  }
  .second {
    animation: pulse 3300ms infinite ease-in;
    animation-delay: 700ms;
  }
  .third {
    animation: pulse 3300ms infinite ease-in;
    animation-delay: 1400ms;
  }
  .fourth {
    animation: pulse 3300ms infinite ease-in;
    animation-delay: 2100ms;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: #e6e6e6;
  }
  25% {
    transform: scale(1);
    background-color: #3e64ff25;
  }

  50% {
    transform: scale(1.1);
    background-color: #3e64ff;
  }

  75% {
    transform: scale(1);
    background-color: #3e64ff25;
  }

  100% {
    transform: scale(1);
    background-color: #e6e6e6;
  }
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 1.2rem;
    width: 100%;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-next {
      margin-left: auto;
    }
  }

  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.form {
  width: 100%;
  &__item {
    &-select-bank {
      background: theme('colors.white.DEFAULT') !important; 
    }
  }
}

.recommend-container {
  margin-bottom: 10rem;
}

.recommend {
  p {
    font-size: 1.2rem;
    font-weight: 800;
    color: theme('colors.black.DEFAULT');
    margin-bottom: 1.2rem;
  }
}

.recommend-mobile {
  background: theme('colors.white.DEFAULT');
  border-radius: 1.2rem;
  padding: 2rem;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.5);
  ul {
    padding: 0rem 2rem;

    list-style: none;
  }
  ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: theme('colors.primary'); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}

.recommend-desktop {
  background: theme('colors.white.DEFAULT');
  border-radius: 1.2rem;
  padding: 2rem;
  color: rgba(0, 0, 0, 0.5);
  ul {
    padding: 0rem 2rem;

    list-style: none;
  }
  ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: theme('colors.primary'); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
}

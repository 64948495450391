.content-container {
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px 20px;

  .inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 1.8rem;
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 1.5rem;
      font-weight: normal;
      color: theme('colors.black.dark');
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .positive-button {
    width: 100%;
    height: 45px;
    max-width: 350px;
    background-color: theme('colors.primary');

    &:focus {
      background-color: theme('colors.primary') !important;
    }
  }

  .actions-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancel-button {
      flex: 2;
      height: 45px;
      border-color: theme('colors.primary');
    }

    .positive-button {
      flex: 2;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&display=swap');

@font-face {
  font-family: My-Avenir;
  src: url('./Font/AvenirNextLTPro-Regular.otf');
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: My-Avenir;
  src: url('./Font/AvenirNextLTPro-Bold.otf');
  font-weight: 800;
  font-stretch: normal;
}

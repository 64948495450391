.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-top: 3rem;
  .form {
    width: 100%;
    &__item {
      &-amount {
        background: theme('colors.white.DEFAULT');
        color: theme('colors.primary');
        height: 7.8rem;
        input {
          font-weight: 800;
          font-size: 3.2rem;
          line-height: 3.2rem;
          padding: 2.3rem 0;
          text-align: center;
        }
      }
      &-select-additional {
        background: theme('colors.white.DEFAULT');
      }
      &-text-area {
        background: theme('colors.white.DEFAULT') !important;
        height: 12rem !important;
        textarea {
          max-height: 12rem;
          height: 100% !important;
        }
      }
    }
  }
  &-container {
    padding: 1rem 0;
    width: 50%;
  }
}

.button-container {
  margin-top: 5.4rem;
  display: flex;
  margin-bottom: 2rem;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-next {
      margin-left: auto;
    }
  }

  .content-container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
  }
}

.ant-radio-group {
  $base: 3px;

  .ant-radio-wrapper {
    padding: 1.5rem 0;
    margin-right: 14px;

    span {
      font-family: Avenir;
      font-weight: normal;
      font-size: 1.4rem;
    }

    // .ant-radio {
    //   .ant-radio-input {
    //     width: 100%;
    //     height: 100%;
    //   }
    //   .ant-radio-inner {
    //     width: 20px;
    //     height: 20px;

    //     &::after {
    //       top: 2.6px;
    //       left: 5.2px;
    //       display: inline-block;
    //       transform: rotate(45deg);
    //       height: 3 * $base;
    //       width: 2 * $base;
    //       border-bottom: $base solid theme('colors.white.DEFAULT');
    //       border-bottom-left-radius: 20%;
    //       border-bottom-right-radius: 20%;
    //       border-right: $base solid theme('colors.white.DEFAULT');
    //       border-top-right-radius: 20%;
    //     }
    //   }
    // }

    // .ant-radio-checked .ant-radio-inner {
    //   background-color: theme('colors.primary');
    // }
  }
}

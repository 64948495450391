.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .form {
    width: 100%;

    input {
      height: 4.6rem;
    }
    &__item {
      height: 10rem;
      display: block !important;
      &-email {
        background: theme('colors.white.DEFAULT') !important;
      }
      &-password {
        background: theme('colors.white.DEFAULT') !important;
      }
    }
  }
  &-container {
    padding: 1rem 0;
    width: 50%;
  }
}

.icon-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
}

.title-container {
  color: theme('colors.black.DEFAULT');
  text-align: center;
  margin-bottom: 3rem;
  .title {
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
  .subtitle {
    font-size: 1.4rem;
    opacity: 0.4;
  }
}

.button-container {
  margin-top: 5.4rem;
  display: flex;
  margin-bottom: 2rem;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

.forgot-password-container {
  //width: 100%;
  text-align: center;
  span {
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: theme('colors.primary');
  }
}

.forgot-password-item {
  label[for='password'] {
    width: 100% !important;
  }
  .forgot-password-container {
    display: flex;
    width: -webkit-fill-available;
    a {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-continue {
      margin-left: auto;
    }
  }
  .progress {
    margin-bottom: 1rem;
  }
  .content-container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
  }
}

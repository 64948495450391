.ant-picker {
  width: 100%;
  max-width: 500px;
  border: none;
  background-color: theme('colors.gray.lighter');
  height: 56px;
  padding: 10px;
  font-family: Avenir;
  font-size: 1.4rem;

  .ant-picker-suffix {
    color: theme('colors.primary');
  }
}

.ant-picker-focused {
  border-color: theme('colors.blue.DEFAULT');
  background-color: theme('colors.white.DEFAULT');
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  &-container {
    margin-top: 7rem;
    padding: 1rem 0;
    width: 50%;
    .icon-container {
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
    }
    .qr-container {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
  }
  .form {
    width: 100%;
    &__item {
      &-select-bank {
        background: theme('colors.white.DEFAULT');
      }
    }
  }
}

.button-container {
  margin-top: 5.4rem;
  display: flex;
  margin-bottom: 2rem;
  button {
    width: 100%;
    height: 5.6rem;
  }
}

.title-container {
  text-align: center;

  color: theme('colors.black.DEFAULT');
  margin-bottom: 3rem;
  .title {
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
  }
  .subtitle {
    font-size: 1.4rem;
    opacity: 0.4;
  }
}
.card {
  margin-bottom: 2rem;
  &__billing {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 1px solid theme('colors.gray.border');
    font-weight: 800;
    font-size: 1.4rem;
    p:nth-child(1) {
      font-size: 1.2rem;
      opacity: 0.4;
    }
  }
  &__billing:last-child {
    border-bottom: none;
    p:nth-child(2) {
      color: theme('colors.primary');
      font-weight: 800;
      font-size: 1.8rem;
    }
  }
  &__copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    max-height: 5.8rem;
    cursor: pointer;
    input {
      width: auto;
      background: transparent !important;
      padding: 0 !important;
      margin-left: 0.6rem;
      font-weight: 800;
      font-size: 1.8rem;
    }
  }
}



@media screen and (max-width: 500px) {
  .button-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 2.5rem;
    padding: 0 1.2rem;
    left: 0;
    width: 100%;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    .button-next {
      margin-left: auto;
    }
  }

  .content-container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .content-container {
    flex-grow: 1;
  }
}

.header {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 7rem;
  align-items: center;
  background: theme('colors.white.DEFAULT');
  padding: 0 1.5rem;
  .header__left {
    display: flex;
    align-items: center;
    .logo {
      display: flex;
    }
  }

  .header__right {
    display: flex;
    align-items: center;
    height: 100%;
    .header_info {
      display: flex;
      align-items: center;
      .info_avatar {
        margin-right: 2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info_name {
      font-weight: 700;
      font-size: 1.4rem;
      margin-right: 1.3rem;
    }
  }
}

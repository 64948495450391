.ant-form-item-label {
  label {
    font-style: normal;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-left: 0.3rem;
  }
}
.ant-col-24.ant-form-item-label {
  padding-bottom: 1.2rem;
}
.ant-form-item {
  margin-bottom: 2rem;
}

.ant-form-item-required {
  max-height: 1.2rem;
}

.dropdown_box {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  height: 100%;

  &.hover {
    background-color: transparent;
  }
}
